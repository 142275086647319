import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { graphql, Link } from 'gatsby';
import axios from 'axios';
import SimpleReactLightbox, { SRLWrapper } from 'simple-react-lightbox';

import Layout from '../components/Layouts/Layout';
import SEO from '../components/seo';
import Spinner from '../components/Spinner';

// Fetch data from local images folder
// export const EventsImagesPage = graphql`
//   query EventsImagesPage($folder: String) {
//     allFile(filter: { relativeDirectory: { eq: $folder } }) {
//       nodes {
//         id
//         publicURL
//       }
//     }
//   }
// `

export const EventsImagesPage = graphql`
  query EventsImagesPage($photoset: String) {
    allEventsJson(filter: { photoset: { eq: $photoset } }) {
      nodes {
        photoset
        title
        date
      }
    }
  }
`;

function EventsImages({ data }) {
  const { nodes } = data.allEventsJson;
  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const fetchImages = await axios({
        method: 'get',
        url: 'https://api.flickr.com/services/rest',
        params: {
          method: 'flickr.photosets.getPhotos',
          api_key: '0402b0acfa4fb3ffaf9d3f4dc6824c46',
          photoset_id: nodes[0].photoset,
          extras: 'url_n, owner_name, date_taken, views',
          page: 1,
          format: 'json',
          nojsoncallback: 1,
        },
      });

      const { data: result } = fetchImages;
      const picsArray = result.photoset.photo.map(
        pic =>
          `https://farm${pic.farm}.staticflickr.com/${pic.server}/${pic.id}_${pic.secret}_b.jpg`
      );
      setImages(picsArray);
      setLoading(false);
    };
    fetchData();
  }, [nodes]);

  return (
    <Layout>
      <SEO title={nodes[0].title} />
      {loading ? (
        <Spinner />
      ) : (
        <SimpleReactLightbox>
          <section className='relative bg-gray-200'>
            <div className='container mx-auto px-4 py-16'>
              <section className='mt-12'>
                <div className='text-white px-6 sm:px-6 py-4 border-0 rounded relative mb-4 bg-orange-500'>
                  <div className='flex justify-between items-center'>
                    <Link
                      className='background-transparent uppercase px-4 py-2 text-md outline-none focus:outline-none ease-linear transition-all duration-150'
                      to='/gallery'
                    >
                      <i className='fas fa-arrow-circle-left text-3xl'></i>
                    </Link>
                    <h2 className='font-semibold text-3xl text-base'>
                      {nodes[0].title}
                    </h2>
                    <h2 className='font-semibold text-base'>{nodes[0].date}</h2>
                  </div>
                </div>
              </section>
              <div className='items-center justify-center flex flex-wrap'>
                <SRLWrapper>
                  <div className='image-container'>
                    {images.map(image => (
                      <div>
                        <a href={image}>
                          <img src={image} alt={nodes[0].title} />
                        </a>
                      </div>
                    ))}
                  </div>
                </SRLWrapper>
              </div>
            </div>
          </section>
        </SimpleReactLightbox>
      )}
    </Layout>
  );
}

EventsImages.propTypes = {
  data: PropTypes.array,
};

export default EventsImages;
